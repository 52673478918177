import React from "react"
import { Layout } from "@components/layout"
import { Breadcrumbs } from "@components/shared"
import { BlogHero, PostPreview } from "@components/blog"
import { PostItem } from "../blog/blog"
import {
  wrapper,
  tags,
  tags__headers,
  tags__items,
} from "./styles/tags.module.scss"

const renderWithAllProps = (Comp) => (entry, key) => (
  <Comp {...entry} key={key} />
)

const Tag = ({ pageContext }) => {
  const { page, tag, posts } = pageContext

  return (
    <Layout {...page}>
      <div className={tags}>
        <div className={tags__headers}>
          <h1>#{tag.name}</h1>
        </div>
        <div className={tags__items}>
          {posts.map((el, k) => (
            <PostItem {...el} key={k} />
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default Tag
