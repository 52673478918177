import React from "react"
import { useActions, LangCtx, useContextState } from "@components/contexted"
import { Layout, Parallax } from "@components/layout"
import {
  Breadcrumbs,
  Modal,
  AnimatedLink,
  ResizedImage,
  AnimatedSection,
  AnimatedButton,
} from "@components/shared"
import { withHtml } from "@components/logic"
import dayjs from "dayjs"
import { ProductModal } from "@components/ecommerce"

import {
  blog,
  blog__wrapper,
  blog__wrapper__headers,
  blog__wrapper__posts,
  blog__wrapper__posts__item,
  blog__wrapper__posts__item__nav,
  blog__wrapper__posts__item__headers,
  blog__wrapper__posts__item__tags,
  blog__wrapper__posts__item__tags__tag,
  blog__wrapper__posts__item__nav__date,
  blog__wrapper__posts__item__nav__read,
  circle,
  circle2,
} from "./styles/blog.module.scss"
import CallendarSVG from "./icons/callendar.svg"
import BookSVG from "./icons/book.svg"

function truncateString(str, num) {
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + "..."
}
const Title = withHtml("h4")
const Content = withHtml("p")
const Tags = ({ tags }) => {
  const { nodes } = tags
  return (
    <div className={blog__wrapper__posts__item__tags}>
      {nodes.map(({ slug, name }, i) => {
        return (
          <AnimatedLink
            to={`/tags/${slug}/`}
            key={i}
            className={blog__wrapper__posts__item__tags__tag}
          >
            {"#" + name}
          </AnimatedLink>
        )
      })}
    </div>
  )
}
const PostItem = ({
  title,
  slug,
  content,
  date,
  featuredImage,
  tags,
  categories,
  lang,
}) => {
  let clean = content.replace(/<\/?[^>]+(>|$)/g, "")
  return (
    <AnimatedLink
      className={blog__wrapper__posts__item}
      style={{
        backgroundImage: `url(${featuredImage.node.sourceUrl})`,
      }}
      to={`/${lang}/blog/${slug}`}
    >
      <div className={blog__wrapper__posts__item__headers}>
        <h4>{title}</h4>
        <p>{truncateString(clean, 140)}</p>
      </div>
      <Tags tags={tags} />
      <div className={blog__wrapper__posts__item__nav}>
        <div className={blog__wrapper__posts__item__nav__date}>
          <img src={CallendarSVG}></img>
          {dayjs(date).format("DD.MM.YYYY")}
        </div>
        <div className={blog__wrapper__posts__item__nav__read}>
          <img src={BookSVG}></img>
          <AnimatedLink to={`/${lang}/blog/${slug}`}>
            {lang === "pl" ? "Przeczytaj" : "Read"}
          </AnimatedLink>
        </div>
      </div>
    </AnimatedLink>
  )
}

const Blog = ({ pageContext }) => {
  const { posts, page } = pageContext
  const { language } = useContextState(LangCtx, ["language"])
  const translationUrls = {
    pl: `/pl/blog/`,
    en: `/en/blog/`,
  }
  let languagePosts = posts.filter(
    (el) => el.language.code === language.toUpperCase()
  )
  if (language === "en") {
    languagePosts.reverse()
  }
  console.log("p", language)
  return (
    <Layout {...page[0]} translationUrl={translationUrls}>
      <div className={circle} />
      <div className={blog}>
        <AnimatedSection className={blog__wrapper}>
          <div className={blog__wrapper__headers}>
            <h1>Blog</h1>
          </div>
          <div className={blog__wrapper__posts}>
            {languagePosts.map((el, k) => (
              <PostItem {...el} key={k} lang={language} />
            ))}
          </div>
        </AnimatedSection>
      </div>
      {posts.length > 4 && <div className={circle2} />}
    </Layout>
  )
}
export { PostItem }
export default Blog
